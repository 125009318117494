.login {
    display: grid;
    background-color: #f2f2f2;
    place-items: center;
    height: 100vh;
}
.login__container {
    display: flex;
    flex-direction: column;
}
.login__container > img {
    object-fit: contain;
    height: 200px;
}