.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
}

.header__left {
    display: flex;
    align-items: center;
}

.header__left > img {
    object-fit: contain;
    height: 80px;
    margin-left: 5px;
}
.header__middle {
    display: flex;
    flex: 0.7;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: whitesmoke;
}

.header__middle > .MuiSvgIcon-root {
    color: grey;
}

.header__middle > input {
    width: 100%;
    border: none;
    padding: 10px;
    font-size: medium;
    outline: none;
    background-color: transparent;
}

.header__right {
    display: flex;
    padding-right: 20px;
}