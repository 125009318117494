.emailList {
    flex: 1;
    overflow: scroll;
}
.emailList__settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    background-color: white;
    z-index: 999;
    padding-right: 10px;
}
.emailList__sections {
    position: sticky;
    top: 0;
    display: flex;
    background-color: white;
    z-index: 999;
    border-bottom: 1px solid whitesmoke;
}