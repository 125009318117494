.sidebar__compose {
    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    color: gray;
    padding: 15px !important;
    border-radius: 30px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75);
}

.sidebar__footer {
    display: flex;
    justify-content: center;
}

.sidebar {
    flex: 0.3;
    max-width: 300px;
    padding-right: 20px;
}